import React from "react";

export const Team = ({ data }) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            We have a full staff of experienced, licensed medical professionals
            ready to help you achieve your health and wellness goals!
          </p>
        </div>
        <div id="row">
          {data?.map((employee, i) => (
            <div
              key={`${employee.alt}-${i}`}
              className="col-md-4 col-sm-4 team"
            >
              <div className="thumbnail">
                <img
                  style={{
                    display: !employee.img ? "none" : undefined,
                  }}
                  src={employee.img}
                  alt={employee.alt}
                  className="team-img"
                />
                <div className="caption">
                  <h4>{employee.name}</h4>
                  <h4>{employee.title}</h4>
                  <p>{employee.credentials}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
