import React from "react";
export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div id="footer">
      <div className="container text-center">
        <p>&copy; {year} Coastal Health & Vitality, LLC.</p>
        <p>
          Statements are not guarantees. Coastal Health and Vitality doesn't
          claim to cure, treat or prevent any illness, disease or medical
          condition. These statements are not backed by the FDA. All treatments
          are administered by licensed nurses or advanced practice providers in
          good-standing that determine the suitability of all patients on an
          individual basis. All protocols and procedures are overseen by a
          board-certified, licensed physician.
        </p>
        <p>
          Built with ❤️ and ☕️ by{" "}
          <a href="https://nonni.org" target="_blank" rel="noreferrer">
            nonni.org
          </a>
        </p>
      </div>
    </div>
  );
}
