import React, { useState } from "react";
import AccordionItem from "./AccordianItem";

const Accordion = (props) => {
  const [clicked, setClicked] = useState(-1);

  return (
    <ul className="accordion">
      {props.faqs
        ? props.faqs.map((faq, i) => (
            <AccordionItem
              key={i}
              {...{
                faq,
                onToggle: () => setClicked(clicked === i ? -1 : i),
                active: clicked === i,
                key: `${faq.question}-${i}`,
                className: "col-xs-6 col-md-12",
              }}
            />
          ))
        : "Loading..."}
    </ul>
  );
};

export default Accordion;
