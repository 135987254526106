import React from "react";
export const Contact = ({
  street,
  cityState,
  email,
  phone,
  instagram,
  facebook,
}) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <iframe
                  title="Coastal Health & Vitality Contact Form"
                  id="contact-form"
                  src="https://app.remedly.com/contact-us/92712"
                  style={{ marginTop: "1em" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker" />
                  Address
                </span>
                <a href="https://goo.gl/maps/RV94anqJmHrgE7BY6">
                  {street}
                  <br />
                  {cityState}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-open" />
                  Email
                </span>{" "}
                <a href={`mailto:${email}`}>{email}</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone" />
                  Phone
                </span>{" "}
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href={facebook ?? "/"}>
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href={instagram ?? "/"}>
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
