import React, { useRef } from "react";

const AccordionItem = ({ faq: { id, question, answer }, active, onToggle }) => {
  const contentElement = useRef();

  return (
    <div>
      <li className={`accordionItem ${active ? "active" : ""}`}>
        <button className="button" onClick={onToggle}>
          {question}
          <span className="control">{active ? "—" : "+"} </span>
        </button>
        <div
          ref={contentElement}
          className="answerWrapper"
          style={
            active
              ? { height: contentElement.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className="answer">
            {answer.map((a) =>
              id === "causes" ? (
                <p style={{ textAlign: "left", color: "inherit" }}>
                  <span
                    style={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    {a[0]}
                  </span>
                  <br />
                  {a[1]}
                </p>
              ) : (
                <p style={{ textAlign: "left", color: "inherit" }}>{a}</p>
              )
            )}
          </div>
        </div>
      </li>
    </div>
  );
};

export default AccordionItem;
