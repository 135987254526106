import React from "react";
export const Header = ({ title, paragraph }) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <div id="logo-container">
                  <img
                    id="logo"
                    alt="coastal hydration therapy logo"
                    src="img/logo/Transparent_Logo.png"
                  />
                </div>
                <h3 className="header-custom-h3">{title}</h3>
                <p>{paragraph}</p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
